<template>
  <div style="margin-top:20px">
    <div class="q-answer" v-if="question.type === '问答题'">
      <div class="stu-score"><span class="label">正确答案:</span> <span v-html="question.answer"></span></div>
    </div>
    <div class="q-answer" v-if="question.type === '翻译题'">
      <div class="stu-score"><span class="label">正确答案:</span> <span v-html="question.answer"></span></div>
    </div>
    <div class="q-answer" v-if="question.type === '写作题'">
      <div class="stu-score" v-if="question.setStandardAnswer"><span class="label">样文:</span> <span
          v-html="question.answer"></span>
      </div>
    </div>
    <div class="q-answer" v-if="question.type === '填空题'"><span class="label">正确答案:</span>
      <span v-for="(answer, key, index) in question.answer" :key="index">
        <span v-if="index > 0">.</span>
        {{ answer[index] }}
      </span>
    </div>
    <div class="q-answer" v-if="question.subType == '选项'">
    </div>
    <div class="q-answer"
      v-if="question.type !== '问答题' && question.type !== '综合题' && question.subType !== '选项' && question.type !== '写作题' && question.type !== '视听题' && question.type !== '填空题' && question.type !== '翻译题'">
      <div class="stu-score"><span class="label">正确答案:</span>
        <span v-for="(answer, index) in question.answer" :key="index">
          <span v-if="index > 0">.</span>
          {{ String.fromCharCode(answer + 65) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: null
    },
  },
  computed: {
  },
  mounted() {
  }
}
</script>

<style scoped>
.label {
  color: #999
}

.v-html {
  display: flex;
}

.v-html>.label {
  margin-right: 10px;
}

.feedback {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>