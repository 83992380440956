export const CLIENT_ACCOUNT = 'https://clientaccountserver.lessonplan.cn';
export const PASSPORT_CLIENT = 'https://clientpassport.lessonplan.cn';
export const TEAMURL = 'https://templateserver.lessonplan.cn';
export const TSINGHUA_API = 'https://tsinghuashowserver.lessonplan.cn';
export const ORGURL = 'https://accountorg.lessonplan.cn';

export const CODE_API = 'https://codeserver.lessonplan.cn';
export const ACCOUNT_API = 'https://accountserver.lessonplan.cn';
export const IMGURL = 'https://img.lessonplan.cn/';
export const COMMITRECORD_API = 'https://commitrecordserver.lessonplan.cn';
export const ROSTER_API = 'https://rosterserver.lessonplan.cn';
export const ElectronAPI = 'https://electronserve.lessonplan.cn';

export const lessonplay_data = "https://lessonplaydata.lessonplan.cn";
//export const lessonplay_data = "http://localhost:5105";

export const PAYORDER_API = 'https://payorderserver.lessonplan.cn';
//export const PAYORDER_API = 'http://localhost:5150';

export const SPM_API = 'https://secondplatformserver.lessonplan.cn';
//export const SPM_API = 'http://localhost:3501';

export const DigitalTextbookServer = 'https://digital-textbook-manage-server.lessonplan.cn';
//export const DigitalTextbookServer = 'http://localhost:5170'
