import passport from './interfaces/passport';
import unifydata from './interfaces/unifydata';
import digitalBook from './interfaces/digitalBook';
import accounts from './interfaces/accounts';
import payorder from './interfaces/payorder';
import download from './interfaces/download';
import bookManage from './interfaces/bookManage';
import feedback from './interfaces/feedback';

export default {
    passport,
    unifydata,
    digitalBook,
    accounts,
    payorder,
    download,
    bookManage,
    feedback
}