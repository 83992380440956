<template>
  <div>
    <div class="login-box" v-if="!islogin">
      <a-button type="primary" size="large" class="login-btn" @click="loginIn">请先登录</a-button>
    </div>
    <div class="login-box" v-else-if="!isAdmin">
      <a-alert message="对不起,您不是管理员" type="warning" />
    </div>
    <div class="admin-contain" v-else>
      <div class="coupon-top-box">
        <div class="coupon-title">优惠券列表</div>
        <a-button class="coupon-btn" type="primary" @click="openBox">生成优惠券</a-button>
      </div>
      <div class="coupon-list">
        <a-table :dataSource="couponList" :columns="columns" />
      </div>
    </div>

    <a-modal v-model:visible="showBox" width="900px" :bodyStyle="bodyStyle" title="批量生成优惠券" :footer="null">
      <div class="modal-item-box">
        <div class="modal-item">
          <span>选择类型：</span>
          <a-select v-model:value="couponType" style="width: 200px">
            <a-select-option :value="item.type" v-for="item in typeList">{{ item.name }}</a-select-option>
          </a-select>
        </div>
        <div class="modal-item" v-if="couponType == 'assign'">
          <span>商品列表：</span>
          <a-select v-model:value="chooseGoods" style="width: 200px">
            <a-select-option :value="item.pk_store_book_id" v-for="item in storeBooks">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="modal-item-box">
        <div class="modal-item">
          <span>过期时间：</span>
          <a-date-picker v-model:value="deadline" style="width: 200px" placeholder="不选择则是无限期" />
        </div>
        <div>
          <span>生成数量：</span>
          <a-input-number v-model:value="couponNum" :min="1" :max="1000" />
        </div>
        <a-button class="modal-btn" type="primary" :loading="isCrating" @click="batchCreate">批量生成</a-button>
      </div>
      <div class="newCoupon-box">
        <div class="newCoupon-item" v-for="(item, i) in newCouponList">
          <span>{{ item }}</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import navroot from "@/component/public/nav/nav.vue";
import { PASSPORT_CLIENT_SERVER, ONLINEURL } from '@/config/url';

export default defineComponent({
  name: 'admin',
  components: {
    navroot
  },
  data() {
    return {
      islogin: false,
      isAdmin: false,
      showBox: false,
      isCrating: false,

      adminRange: ['718e641b-e2f7-322a-77e1-09698bb4b781', '277aa500-41c2-5865-2934-5d99e8f5d28c'],
      columns: [
        { title: '激活码', dataIndex: 'pk_couponGuid', key: 'pk_couponGuid' },
        { title: '优惠券类型', dataIndex: 'typeName', key: 'typeName' },
        { title: '商品名', dataIndex: 'title', key: 'title' },
        { title: '属于用户', dataIndex: 'belongToUser', key: 'belongToUser' },
        { title: '是否已使用', dataIndex: 'isUsed', key: 'isUsed' },
        { title: '激活时间', dataIndex: 'usedTime', key: 'usedTime' },
        { title: '过期时间', dataIndex: 'deadline', key: 'deadline' }
      ],
      storeBooks: [],
      couponList: [],
      typeList: [],
      newCouponList: [],


      bodyStyle: {
        "min-height": "400px",
      },
      couponType: "",
      chooseGoods: "",
      deadline: null,
      couponNum: 1,
    }
  },
  mounted() {
    const state = this.$store.state;
    let user = state.userInfo;
    this.storeBooks = state.storeBooks;
    if (!user) return;
    this.islogin = true;
    if (this.adminRange.indexOf(user.PK_UserGuid) == -1) return;
    this.isAdmin = true;
    this.getCouponList();
  },
  methods: {
    //跳转登录
    loginIn() {
      location.href = `${PASSPORT_CLIENT_SERVER}/yuanxi?redirectURI=${location.href}`;
    },
    //获取优惠券列表
    async getCouponList() {
      let res = await this.$api.payorder.getCouponList({ fk_userGuid: '' });

      if (res.statusCode != 200) return this.$message.error(res.message);;
      this.typeList = res.typeList.filter(item => item.type != 'discount' && item.type != 'reduce');

      res.data.forEach(item => {
        if (item.status == 2) item.isUsed = "已使用";
        else item.isUsed = "未使用";
        item.belongToUser = item.fk_userGuid ? "是" : "否";

        item.couponConfig = item.couponConfig ? JSON.parse(item.couponConfig) : "";
        if (item.couponConfig?.fk_goodsGuid) {
          let digitalBook = this.storeBooks.find(book => book.pk_store_book_id == item.couponConfig.fk_goodsGuid);
          if (digitalBook) item.title = `《${digitalBook.title}》`;
        }
      })
      this.couponList = res.data;
    },
    //打开生成二维码弹窗
    openBox() {
      this.showBox = true;
      this.couponType = this.typeList[0].type;
      this.chooseGoods = this.storeBooks[0].pk_store_book_id;
    },
    //批量生成
    async batchCreate() {
      if (this.isCrating) return;
      this.isCrating = true;

      let couponConfig = null;
      if (this.couponType == 'assign') couponConfig = { fk_goodsGuid: this.chooseGoods };

      let res = await this.$api.payorder.batchCreate({
        num: this.couponNum,
        couponType: this.couponType,
        deadline: this.deadline,
        couponConfig: couponConfig || ''
      });
      this.isCrating = false;
      if (res.statusCode != 200) return;
      this.$message.success("生成成功");

      let newCouponList = [];
      res.data.forEach(item => { newCouponList.push(item.pk_couponGuid) });
      this.newCouponList = newCouponList;
    }
  },
})

</script>
<style scoped lang="scss">
@import "./admin.scss";
</style>