import { createRouter, createWebHistory } from 'vue-router';
import home from '@/component/home/home.vue';
import allCourse from '@/component/all-course/all-course.vue';
import courseInfo from '@/component/course-info/course-info.vue';
import mycourse from '@/component/mycourse/mycourse.vue';
import payment from '@/component/course-info/payment/payment.vue';
import payorder from '@/component/course-info/payment/pay-order/pay-order.vue';
import payfinish from '@/component/course-info/payment/pay-finish/pay-finish.vue';
import personal from '@/component/personal/personal.vue';
import admin from '@/component/admin/admin.vue';
import columnInfo from '@/component/column/column-info/column-info.vue';
import mydownload from '@/component/mydownload/mydownload.vue';
import login from '@/component/login/login.vue';
import columnList from '@/component/column/column-list.vue';

const routes = [
  //首页跳转Home
  { path: '/', redirect: '/home' },
  { path: '/login', name: 'login', component: login, meta: { refresh: true } },
  { path: '/home', name: 'home', component: home },
  { path: '/home/:type', name: 'homeType', component: home },
  // 所有数字教材
  { path: '/all-course', name: 'allCourse', component: allCourse },
  { path: '/all-course/:type', name: 'allCourseType', component: allCourse },
  // 我的数字教材
  { path: '/mycourse', name: 'mycourse', component: mycourse },
  // 数字教材信息
  { path: '/course-info/:id', name: 'course', component: courseInfo },

  { path: '/column-list', name: 'column-list', component: columnList },
  { path: '/column/:id', name: 'column', component: columnInfo },
  // 支付
  { path: '/payment/:id', name: 'payment', component: payment },
  // 支付订单
  { path: '/payorder/:id', name: 'payorder', component: payorder },
  // 支付完成页
  { path: '/payfinish', name: 'payfinish', component: payfinish },
  { path: '/payfinish/:id', name: 'payfinish', component: payfinish },
  // 阅读器
  { path: '/bookReader/:id', name: 'bookReader', component: () => import('@/component/bookReader.vue') },
  // 个人中心
  { path: '/personal', name: 'personal', component: personal },
  // 管理员
  { path: '/admin', name: 'admin', component: admin },
  { path: '/download', name: 'mydownload', component: mydownload },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.afterEach((to, from) => {
  if (from.meta.refresh) {
    window.location.reload()
  }
})
export default router
