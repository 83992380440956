import axios from '../config'
import { PAYORDER_API } from '../baseURL'

export default {
  find(data) {
    return axios.post(`${PAYORDER_API}/feedback/list`, data)
  },
  findOne(data) {
    const { pk_feedback_id, fk_pay_order_id } = data
    let query = '';
    if (pk_feedback_id) {
      query = `pk_feedback_id=${pk_feedback_id}`
    } else if (fk_pay_order_id) {
      query = `fk_pay_order_id=${fk_pay_order_id}`
    }
    return axios.get(`${PAYORDER_API}/feedback/feedback?${query}`)
  },
  create(data) {
    return axios.post(`${PAYORDER_API}/feedback/feedback`, data)
  },
}