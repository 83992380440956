<template>
    <div class="new-popup-box fadeIn">
        <div class="hanlder-box">
            <svg class="icon-svg popup-close" @click="close">
                <use xlink:href="#icon-guanbi" />
            </svg>
        </div>
        <div class="box-content fadeIn">
            <a-spin :spinning="loading">
                <div class="title-box">
                    <div class="box-title">申请开发票</div>
                    <div class="box-sub-title">{{ props.payorder.name }}</div>
                </div>
                <div class="details-box">
                    <div class="details-title">必填项</div>
                    <div class="details">
                        <div v-for="(item, index) in requireDetails" :key="index" class="details-item"
                            v-show="item.name !== 'taxNumber' || invoice.titleType !== 'personal'">
                            <div class="label">{{ item.label }}</div>
                            <div class="content">
                                <span class="select-item selected" v-if="item.type === 'fixed'">{{ item.content
                                    }}</span>
                                <div class="content-select" v-else-if="item.type === 'select'">
                                    <span class="select-item" :class="{ selected: invoice[item.name] === titem.value }"
                                        v-for="(titem, i) in titleTypes" :key="i"
                                        @click="chooseSelect(item.name, titem)">{{ titem.value }}</span>
                                </div>
                                <a-input v-model:value="invoice[item.name]" :placeholder="'请输入' + item.label"
                                    :disabled="!!feedback.pk_feedback_id" v-else-if="invoice" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="details-box">
                    <div class="details-title">选填项</div>
                    <div class="details">
                        <div v-for="(item, index) in noRequireDetails" :key="index" class="details-item">
                            <div class="label">{{ item.label }}</div>
                            <div class="content">
                                <a-input v-model:value="invoice[item.name]" :placeholder="'请输入' + item.label"
                                    :disabled="!!feedback.pk_feedback_id" v-if="invoice" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-box">
                    <a-button v-if="feedback.status === 0" @click="close">申请已提交，请等待后台处理</a-button>
                    <a-button v-else-if="feedback.status === 1" danger @click="close">发票已发送至您的邮箱，请注意查收</a-button>

                    <template v-else>
                        <a-button type="primary" @click="applyInvoice">提交申请</a-button>
                        <a-button class="cancel" type="primary" @click="close">取消</a-button>
                    </template>
                </div>
            </a-spin>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { message, Modal } from 'ant-design-vue'
import store from "@/store";
import api from "@/services";

const props = defineProps({
    payorder: Object,
})
const emit = defineEmits(['close']);

const requireDetails = ref([
    { label: '发票类型', content: '电子普票', type: 'fixed' },
    { label: '发票内容', content: '商品明细', type: 'fixed' },
    { label: '抬头类型', name: 'titleType', type: 'select', require: true },
    { label: '抬头名称', name: 'titleName', require: true },
    { label: '单位税号', name: 'taxNumber', require: true },
    { label: '收件邮箱', name: 'email', require: true },
])

const noRequireDetails = ref([
    { label: '收票人', name: 'recipient', require: false },
    { label: '联系电话', name: 'phoneNumber', require: false },
    { label: '所在地区', name: 'region', require: false },
    { label: '详细地址', name: 'address', require: false },
])

const titleTypes = ref([
    { value: '个人' },
    { value: '单位' },
])

const feedback = ref({
    fk_pay_order_id: '',
    fk_user_id: '',
    fk_publishing_agency_id: '',
    fk_store_id: '',
    content: '',
    type: 'invoicing'
})

const invoice = ref({
    titleType: '单位',
    titleName: '',
    taxNumber: '',
    recipient: '',
    phoneNumber: '',
    region: '',
    address: '',
})

const loading = ref(true);
const applying = ref(false);

onMounted(() => {
    const { userInfo } = store.state;
    feedback.value.fk_user_id = userInfo.PK_UserGuid;
    feedback.value.fk_pay_order_id = props.payorder.pk_payorderGuid;
    feedback.value.fk_store_id = props.payorder.fk_store_id;
    feedback.value.fk_publishing_agency_id = props.payorder.fk_publishing_agency_id;

    getInvoice();
})

async function getInvoice() {
    loading.value = true;
    const res = await api.feedback.findOne({ fk_pay_order_id: props.payorder.pk_payorderGuid });
    loading.value = false;
    if (res.statusCode !== 200) return message.error(res.message);
    if (!res.data) return;

    feedback.value = res.data;
    if (res.data.content) {
        invoice.value = res.data.content;
    }
}

function chooseSelect(name, titem) {
    if (feedback.value.pk_feedback_id) return;
    invoice.value[name] = titem.value;
}

async function applyInvoice() {
    if (applying.value) return;

    let msg = '';
    for (let item of requireDetails.value) {
        if (item.require && !invoice.value[item.name]) {
            msg = `请输入${item.label}`;
            break;
        }
    }
    if (msg) {
        message.warning(msg);
        return;
    }

    Modal.confirm({
        title: '确认提交',
        content: '申请只能提交一次且无法修改，确定要提交吗?',
        onOk: async () => {
            feedback.value.content = invoice.value;
            applying.value = true;
            const res = await api.feedback.create(feedback.value);
            applying.value = false;
            if (res.statusCode !== 200) {
                return message.error(res.message);
            };
            message.success('提交申请成功');
            close();
        }
    })
}

function close() {
    emit('close');
}

</script>

<style lang="scss" scoped>
.new-popup-box {
    color: #333333;

    .box-content {
        background-color: #fff;
        width: 600px;
        padding: 20px;
        height: auto;
        overflow-y: auto;

        .details-box {
            margin-top: 20px;

            .details-title {
                border-bottom: 1px solid #f0f0f0;
                padding-bottom: 5px;
                margin-bottom: 15px;
            }

            .details {
                .details-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    .label {
                        width: 80px;
                        margin-right: 10px;
                        color: #999999;
                    }

                    .content {
                        flex: 1;

                        .content-select {
                            display: flex;
                            align-items: center;
                        }

                        .select-item {
                            border: 1px solid #eeeeee;
                            color: #333333;
                            background-color: #eeeeee;
                            padding: 3px 14px;
                            border-radius: 15px;
                            font-size: 14px;
                            cursor: pointer;
                            transition: all .3s ease;
                            margin-right: 10px;
                            user-select: none;

                            &.selected {
                                border: 1px solid $theme-color;
                                color: $text-color;
                                background-color: #f6e8ea;
                            }
                        }
                    }
                }
            }
        }

        .btn-box {
            text-align: center;
            margin-top: 40px;

            .cancel {
                margin-left: 20px;
                filter: grayscale(99%);
            }
        }
    }
}
</style>