<template>
  <div class="popup-box">
    <svg class="svg-icon popup-close" @click="close()">
      <use xlink:href="#icon-guanbi" />
    </svg>
    <div class="blackscreen" @click="close()"></div>
    <div class="box-content">
      <img class="cover" :src="certificat" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    certificat: String,
  },
  name: "show_certificat",
  data() {
    return {

    }
  },
  async mounted() {

  },

  methods: {
    //关闭
    close() {
      this.$emit("closeBox", true);
    }
  }
}
</script>

<style scoped lang="scss">
@import "./show_certificat.scss";
</style>