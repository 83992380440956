export const DEFAULT_REDIRECT_URI = 'http://www.lessonplay.cn'
export const PASSPORT_SERVER = 'https://passport.lessonplan.cn'
export const PASSPORT_CLIENT_SERVER = 'https://clientpassport.lessonplan.cn'
export const WX_OPEN_SERVER = 'https://open.weixin.qq.com'
export const Tsinghua_REDIRECT_URI = 'https://tsinghuashow.lessonplan.cn'
export const LESSONPLAY_TOKEN = "LESSONPLAY_TOKEN";
export const IMGURL = 'https://img.lessonplan.cn';
export const CDNURL = 'https://cdn.lessonplan.cn';
export const ONLINEURL = 'https://yuanxi.online';

export const CODEVIEWURL = 'https://digital-textbook.lessonplan.cn';