<template>
  <span class="login yuanxi" v-if="!is_phone">
    <div class="login-box">
      <div class="login-content">
        <div class="login-title-box" v-if="!is_register && !is_forget">
          <div class="login-icon">
            <img src="https://img.lessonplan.cn/IMG/yuanxi/images/yuanxi/icon.png" alt="" />
            <img src="https://img.lessonplan.cn/IMG/yuanxi/images/yuanxi/icon-text.png" alt="" />
          </div>
          <div class="login-title">
            <img src="https://img.lessonplan.cn/IMG/yuanxi/images/yuanxi/title.png" alt="" />
          </div>
        </div>
        <div class="title-text fadeIn" v-if="is_register || is_forget">
          <img src="https://img.lessonplan.cn/IMG/yuanxi/images/yuanxi/forget-title.png" alt="" v-if="is_forget" />
          <img
            src="https://img.lessonplan.cn/IMG/yuanxi/images/yuanxi/register-title.png"
            alt=""
            v-if="is_register"
          />
        </div>

        <div
          class="login-input-box fadeIn"
          v-if="!is_register && !is_forget && !is_wechat"
        >
          <button class="wechat-button my-button" @click="wechatLogin()">
            <div class="wechat-text">
              <img src="https://img.lessonplan.cn/IMG/yuanxi/images/yuanxi/wechat.png" alt="" />
              <span>微信登录</span>
            </div>
          </button>
          <div class="division">
            <i></i>
            <span>or</span>
            <i></i>
          </div>
          <div class="lp-item">
            <div>账号</div>
            <a-input
              v-model:value="account"
              class="my-input"
              placeholder="请输入手机号"
              :maxLength="20"
            />
          </div>
          <div class="lp-item">
            <div>密码</div>
            <a-input-password
              v-model:value="password"
              type="password"
              class="my-input"
              placeholder="请输入密码"
              :maxLength="20"
              @keyup.enter="login()"
            />
          </div>
          <a-checkbox class="rememberMe" v-model:checked="rememberMe"
            >记住密码</a-checkbox
          >
          <a-button
            class="lp-button my-button"
            type="primary"
            :loading="logining"
            @click="login()"
            >登录</a-button
          >
          <div class="lp-info">
            <div class="a-text" @click="is_forget = true">忘记密码？</div>
            <div>
              <span>没有注册？</span>
              <span class="a-text info-register" @click="is_register = true"
                >立即注册</span
              >
            </div>
          </div>
        </div>
        <!-- 微信登录二维码 -->
        <div class="login-input-box fadeIn" :class="{ hide: !is_wechat }">
          <div id="wx-login-qrCode"></div>
          <div class="a-text" @click="is_wechat = false">手机号登录</div>
        </div>
        <!-- 注册 -->
        <div class="login-input-box register-box fadeIn" v-if="is_register">
          <div class="lp-item">
            <div>账号</div>
            <a-input
              v-model:value="formRegister.phone"
              class="my-input"
              placeholder="请输入手机号"
              :maxLength="11"
            />
          </div>
          <div class="lp-item">
            <div>验证码</div>
            <div class="code-box">
              <a-input
                v-model:value="formRegister.code"
                class="my-input"
                placeholder="请输入验证码"
                suffix
                :maxLength="6"
              />
              <!-- 倒计时 -->
              <span class="get-code" v-if="count == 0" @click="getPhoneCode"
                >获取验证码</span
              >
              <span class="get-code" v-else>{{ count }}秒后重试</span>
            </div>
          </div>
          <div class="lp-item">
            <div>密码</div>
            <a-input-password
              v-model:value="formRegister.password"
              type="password"
              class="my-input"
              placeholder="请输入密码"
              :maxLength="20"
            />
          </div>
          <div class="lp-item">
            <div>验证密码</div>
            <a-input-password
              v-model:value="formRegister.checkPassword"
              type="password"
              class="my-input"
              placeholder="请输入再次输入密码"
              :maxLength="20"
            />
          </div>
          <div class="lp-item">
            <div>姓名</div>
            <a-input
              v-model:value="formRegister.nickName"
              class="my-input"
              placeholder="请输入姓名或昵称"
              :maxLength="20"
            />
          </div>
          <a-button
            class="lp-button my-button"
            type="primary"
            :loading="registering"
            @click="checkCode()"
            >确认注册</a-button
          >
          <div class="a-text" @click="returnLogin()">返回登陆</div>
        </div>
        <!-- 忘记密码 -->
        <div class="login-input-box forget-box fadeIn" v-if="is_forget">
          <div class="fadeIn" v-if="forgetStep == 1">
            <div class="lp-item">
              <div>账号</div>
              <a-input
                v-model:value="formRegister.phone"
                class="my-input"
                placeholder="请输入手机号"
                :maxLength="11"
              />
            </div>
            <div class="lp-item">
              <div>验证码</div>
              <div class="code-box">
                <a-input
                  v-model:value="formRegister.code"
                  class="my-input"
                  placeholder="请输入验证码"
                  suffix
                  :maxLength="6"
                />
                <!-- 倒计时 -->
                <span class="get-code" v-if="count == 0" @click="getPhoneCode"
                  >获取验证码</span
                >
                <span class="get-code" v-else>{{ count }}秒后重试</span>
              </div>
            </div>
            <a-button
              class="lp-button my-button"
              type="primary"
              :loading="registering"
              @click="checkCode()"
              >下一步</a-button
            >
          </div>
          <div class="fadeIn" v-if="forgetStep == 2">
            <div class="lp-item">
              <div>密码</div>
              <a-input-password
                v-model:value="formRegister.password"
                type="password"
                class="my-input"
                placeholder="请输入密码"
                :maxLength="20"
              />
            </div>
            <div class="lp-item">
              <div>验证密码</div>
              <a-input-password
                v-model:value="formRegister.checkPassword"
                type="password"
                class="my-input"
                placeholder="请输入再次输入密码"
                :maxLength="20"
              />
            </div>
            <a-button
              class="lp-button my-button"
              type="primary"
              :loading="editPasswording"
              @click="editPassword()"
              >确认修改</a-button
            >
          </div>
          <div class="a-text" @click="returnLogin()">返回登陆</div>
        </div>
      </div>

      <!-- 公安备案 -->
      <div style="text-align: center; margin: 0 auto; padding-top: 20px">
        <a
          target="_blank"
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52010302003322"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img src="https://img.lessonplan.cn/IMG/yuanxi/images/public_security.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #999;
            "
          >
            贵公网安备 52010302003322号
          </p>
        </a>
      </div>
    </div>

    <i class="cover-box"></i>
  </span>
</template>

<script>
import { PASSPORT_SERVER } from "@/config/url";
import { PC_APPID, SCOPE } from "@/config/wx";

export default {
  name: "login",
  components: {},
  data() {
    return {
      userGuid: "",
      formRegister: {
        phone: "",
        password: "",
        checkPassword: "",
        nickName: "",
      },

      loading: true,
      is_wechat: false,
      is_register: false,
      is_forget: false,
      is_phone: false,

      logining: false,
      registering: false,
      editPasswording: false,

      rememberMe: false,

      account: "",
      password: "",
      redirectURI: "",

      count: 0,
      forgetStep: 1,

      pageContent: null,
    };
  },
  computed: {
    
  },
  async mounted() {
    if (this.$store.state.isElectron) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("config:update", {
        username: "",
        password: "",
        token: "",
        userGuid: "",
      });

      ipcRenderer.on("wxLogin:success", async (eventr, data) => {
        this.logining = true;
      //登录
      let res = await this.$api.passport.auth({
        username: data.Name,
        password: data.Password,
      });
      this.logining = false;
      if (this.$store.state.isElectron) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.send("config:update", {
          username: data.Name,
          password: data.Password,
          token: res.token,
          userGuid: res.userGuid,
        });
      }
      this.$message.success("登陆成功");

      //vue路由跳转
      this.$router.replace("/");
      });
      ipcRenderer.on("wxLogin:register", async (eventr, data) => {
        this.is_register=true
      });
    }
    this.fillRemember();
  },
  methods: {
    //判断手机端
    judgePhone() {
      let w_width = window.innerWidth;
      this.is_phone = w_width < 700;
    },
    //微信登陆
    wechatLogin() {
      this.is_wechat = true;
      this.wxloginForPC();
    },
    //生成登录二维码
    wxloginForPC() {
      const wxApiRedirectURI = `${PASSPORT_SERVER}/wx/client_strict`;
      let href = `https://cdn.lessonplan.cn/Public/CSS/wx-login-style.css`;
      //eslint-disable-next-line
      new WxLogin({
        id: "wx-login-qrCode",
        appid: PC_APPID,
        scope: SCOPE,
        redirect_uri: wxApiRedirectURI,
        href: href,
      });
    },
    //账号密码登录
    async login() {
      let msg = "";
      if (!this.account || !this.password) msg = "请输入正确的账号密码";
      if (msg) return this.$message.warning(msg);

      this.judgeRemember();

      this.logining = true;
      //登录
      let res = await this.$api.passport.auth({
        username: this.account,
        password: this.password,
      });
      this.logining = false;

      if (res.status != 1) return this.$message.warning("用户名或密码不正确");
      if (this.$store.state.isElectron) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.send("config:update", {
          username: this.account,
          password: this.password,
          token: res.token,
          userGuid: res.userGuid,
        });
      }
      this.$message.success("登陆成功");

      //vue路由跳转
      this.$router.replace("/");
    },
    //获取验证码
    async getPhoneCode() {
      let formRegister = this.formRegister;
      let count = this.count;

      if (!formRegister.phone || formRegister.phone.length != 11)
        return this.$message.warning("请先输入正确的手机号");
      count = 60;
      count -= 1;
      this.count = count;

      let that = this;
      let countTime = setInterval(() => {
        count -= 1;
        that.count = count;
        if (count == 0) {
          clearInterval(countTime);
        }
      }, 1000);
      this.$message.success("短信已发送，请注意查收");

      let res = await this.$api.accounts.sendSms(formRegister.phone);
      if (res.code != 1) return this.$message.error(res.msg);
      formRegister.codeGuid = res.data.PK_CodeGuid;
    },
    //验证忘记密码
    async checkCode() {
      let registering = this.registering;
      if (registering) return;
      let { phone, codeGuid, code } = this.formRegister;

      let msg = ``;
      if (!phone || phone.length != 11) msg = "请输入正确的手机号";
      else if (!codeGuid) msg = "请先获取验证码";
      if (msg) return this.$message.warning(msg);

      registering = true;
      //先验证验证码
      let res = await this.$api.accounts.checkcode(codeGuid, code);
      if (res.code != 1) {
        this.registering = false;
        return this.$message.error(res.msg);
      }

      if (this.is_register) this.register();
      else {
        //找到用户
        let userList = await this.$api.accounts
          .getClientByUser(phone)
          .then((res) => res.data || []);
        if (userList.length == 0) {
          registering = false;
          return this.$message.warning("未找到该用户，请确认手机号是否正确");
        }
        this.userGuid = userList[0].PK_UserGuid;
        registering = false;
        this.forgetStep = 2;
      }
    },
    //确定注册
    async register() {
      let { phone, password, checkPassword, nickName } = this.formRegister;
      let msg = "";

      if (!/^1[3456789]\d{9}$/.test(phone)) msg = "请输入正确的手机号";
      else if (!password || password.length < 6)
        msg = "密码不符合规则，请输入6位以上密码";
      else if (checkPassword != password) msg = "两次输入的密码不一致";
      else if (!nickName) msg = "请输入姓名";
      if (msg) return this.$message.warning(msg);

      nickName = nickName.trim();
      let res = await this.$api.accounts.register({
        Name: phone,
        Phone: phone,
        Password: password,
        NickName: nickName,
      });
      this.registering = false;
      if (res.status != 1) {
        this.formRegister.code = "";
        this.formRegister.codeGuid = "";
        return this.$message.error(res.err);
      }

      this.$message.success("注册成功");
      this.returnLogin();
    },
    //修改密码
    async editPassword() {
      let editPasswording = this.editPasswording;
      if (editPasswording) return;
      let { password, checkPassword } = this.formRegister;

      let msg = "";
      if (!password) msg = "请输入新密码";
      else if (checkPassword.length < 6) msg = "请至少输入6位密码";
      else if (!checkPassword) msg = "请再次输入新密码";
      else if (password != checkPassword) msg = "两次输入的密码不一致";

      if (msg) return this.$message.warning(msg);

      editPasswording = true;
      let res = await this.$api.accounts.setNewPassword(this.userGuid, password);
      editPasswording = false;
      if (res.status != 1) return this.$message.error(res.message);

      this.$message.success("密码重置成功");
      this.forgetStep = 1;
      this.returnLogin();
    },

    //返回登录
    async returnLogin() {
      this.is_wechat = false;
      this.is_register = false;
      this.is_forget = false;
      this.formRegister = {
        phone: "",
        password: "",
        checkPassword: "",
        nickName: "",
        codeGuid: "",
        code: "",
      };
      //隐藏二维码
      document.querySelector("iframe");
    },
    //修改标签图标
    changeFavicon(link) {
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = link;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.href = link;
        document.head.appendChild($favicon);
      }
    },

    //记住密码
    judgeRemember() {
      // 在此处进行用户名和密码的加密
      const encryptedU = btoa(this.account);
      const encryptedP = btoa(this.password);

      // 在这里可以根据rememberMe的值决定是否将加密后的用户名和密码存储在本地存储（localStorage）中
      if (this.rememberMe) {
        localStorage.setItem("encryptedU", encryptedU);
        localStorage.setItem("encryptedP", encryptedP);
      } else {
        localStorage.removeItem("encryptedU");
        localStorage.removeItem("encryptedP");
      }
    },
    //填入已记住密码
    fillRemember() {
      // 在页面加载时检查是否存在记住密码的数据，并进行解密
      const encryptedU = localStorage.getItem("encryptedU");
      const encryptedP = localStorage.getItem("encryptedP");

      if (encryptedU && encryptedP) {
        // 在此处进行解密操作
        this.account = atob(encryptedU);
        this.password = atob(encryptedP);
        this.rememberMe = true;
      }
    },

    //组件销毁
    beforeUnmount() {
      window.removeEventListener("resize", cancalDebounce);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./login.scss";
</style>
