<template>
    <div>
        <div class="questions">
            <div class="question" v-for="(item, i) in questions">
                <span class="question-num">{{ i + 1 }}. </span>
                <span>{{ item.content }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        questions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
        }
    },
    async mounted() {
    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.questions
{
    .question
    {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 7px;
        border: 1px solid #6c6c829e;
        background-image: linear-gradient(to bottom, #ab398233, #ffb6c11a);

        .question-num
        {
            font-weight: 500;
        }
    }
}
</style>