<template>
  <!-- 个人中心 -->
  <div class="personal-box no-image">
    <infoBackground></infoBackground>
    <div class="person-head">
      <div class="personal">
        <div class="avatar" v-if="userInfo">
          <i class="avatar-icon" :style="{ backgroundImage: 'url(' + userInfo.HeadPhotoPath + ')' }"></i>
          <p>{{ userInfo.NickName }}</p>
          <div class="vip-info" v-if="studentVip">
            {{ studentVip.name }}
            <span v-if="studentVip.deadline">{{ studentVip.deadline }}到期</span>
            <span v-if="studentVip.isPastdue">(已过期)</span>
          </div>
        </div>
      </div>
      <div class="person-menu">
        <div class="menu">
          <span :class="{ selected: currentType == item.type }" v-for="item in menuList"
            @click="currentType = item.type">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="person-content" v-if="userInfo">
      <userInfo @refreshVIP="refreshVIP()" v-if="currentType == 'user-info'"></userInfo>
      <buyOrder v-if="currentType == 'buy-order'"></buyOrder>
      <!-- <achievement_list v-if="currentType == 'achieve'"></achievement_list>
      <certificat_list v-if="currentType == 'certificat'"></certificat_list> -->
      <portraitsInfo v-if="currentType == 'portraits-info'"></portraitsInfo>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import { PASSPORT_CLIENT_SERVER } from '@/config/url';
import * as moment from "moment";

import foot from "@/component/public/foot/foot.vue";
import navroot from "@/component/public/nav/nav.vue";
import infoBackground from "@/component/public/info-background/info-background.vue";

import userInfo from "@/component/personal/user-info/user-info.vue";
import buyOrder from "@/component/personal/buy-order/buy-order.vue";
import achievement_list from "@/component/personal/achievement_list/achievement_list.vue";
import certificat_list from '@/component/personal/certificat_list/certificat_list.vue';
import portraitsInfo from '@/component/personal/portraits-info/portraits-info.vue';

export default {
  components: {
    foot,
    navroot,
    infoBackground,
    userInfo,
    buyOrder,
    achievement_list,
    certificat_list,
    portraitsInfo
  },
  data() {
    return {
      userInfo: null,
      menuList: [
        { name: '个人信息', type: 'user-info' },
        { name: '已购订单', type: 'buy-order' },
        // { name: '徽章/证书', type: 'achieve' },
        { name: '阅读数据', type: 'portraits-info' }
      ],
      currentType: "user-info",

      studentVip: null,
    }
  },
  async mounted() {
    this.$store.commit('showReturn', true);

    const { userInfo, studentVip } = this.$store.state;
    if (!userInfo) {
      if (this.$store.state.isElectron) {
        this.$router.push('/login');
      } else {
        location.href = `${PASSPORT_CLIENT_SERVER}/yuanxi?redirectURI=${location.href}`;
      }
      return
    }

    this.userInfo = userInfo;
    this.studentVip = studentVip;
  },
  methods: {
    //刷新vip
    async refreshVIP() {
      const studentVip = await this.$api.payorder.getStudentVip(this.userInfo.PK_UserGuid).then(res => res.data || null);
      if (!studentVip) return;
      const vips = studentVip.content ? JSON.parse(studentVip.content) : [];
      const vip = vips.find((item) => item.type == VIP_PLATFORM);
      if (vip) {
        vip.isPastdue = !vip.deadline || new Date() < new Date(item.deadline) ? false : true;
        vip.deadline = vip.deadline ? moment(vip.deadline).format('YYYY-MM-DD') : null;

        this.$store.commit("studentVip", vip);
      }

      this.findVip();
    }
  }
}
</script>

<style scoped lang="scss">
@import "./personal.scss";
</style>