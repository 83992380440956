<template>
  <div class="phone-bar">
    <div class="bar-item" :class="{ actived: item.actived }" @click="chooseBar(item)" v-for="item of menuList">
      <svg class="icon-svg">
        <use :xlink:href="item.icon+'-actived'"  v-if="item.actived "/>
        <use :xlink:href="item.icon"  v-else/>
      </svg>
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>

import { PASSPORT_CLIENT_SERVER } from '@/config/url';

export default {
  name: "phoneBar",
  components: {},
  props: {
    returnNav: String
  },
  data() {
    return {
      menuList: [
        { name: "阅读", icon: "#icon-read", url: "/home" },
        { name: "书架", icon: "#icon-bookshelf", url: "/mycourse" },
        { name: "专栏", icon: "#icon-column", url: "/column-list" },
        { name: "我的 ", icon: "#icon-person", url: "/personal" },
      ],
      userInfo: null,
    }
  },
  async mounted() {
    let { userInfo } = this.$store.state;
    this.userInfo = userInfo;

    this.judgeMenu();
  },
  methods: {
    //判断当前菜单
    judgeMenu() {
      this.$nextTick(() => {
        this.menuList.forEach(menu => {
          if (menu.url != '/' && location.href.indexOf(menu.url) > -1) menu.actived = true;
          else menu.actived = false;
        })
      })
    },
    //选择底部
    chooseBar(item) {
      if (item.name != '首页' && !this.userInfo) {
        return this.$nzconfirm('是否跳转登录?', () => {
          if (this.$store.state.isElectron) {
            this.$router.push('/login');
          } else {
            location.href = `${PASSPORT_CLIENT_SERVER}/yuanxi?redirectURI=${location.href}`;
          }
        })
      }

      $('#content').animate({ scrollTop: 0 }, 300);
      this.$router.push(item.url)

      this.menuList.forEach(menu => {
        menu.actived = false;
        if (menu.name == item.name) menu.actived = true;
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import "./phone-bar.scss";
</style>