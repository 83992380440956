<template>
    <div class="studyRecord">
        <div class="int-item" v-for="int in inductionList">
            <div class="int-item-info" :class="{ 'unFoldApp': int.unFoldApp, 'hasApp': int.appList?.length }"
                @click="int.unFoldApp = !int.unFoldApp">
                <svg class="int-item-icon icon">
                    <use xlink:href="#icon-xiala"></use>
                </svg>
                <div class="int-item-title">{{ int.title }}</div>
                <div class="int-item-progress">
                    <a-tooltip :title="item.name + ':' + item.value + item.unit" v-for="item in int.intStatisticList">
                        <a-progress class="progress-item" :percent="item.percent" type="circle" status="normal"
                            :width="24" :strokeWidth="12" :strokeColor="'#AB3982'">
                            <template #format>
                                <span>{{ item.value }}</span>
                            </template>
                        </a-progress>
                    </a-tooltip>
                </div>
            </div>
            <div class="int-item-apps" v-if="int.appList?.length && int.unFoldApp">
                <div class="int-item-app" v-for="app in int.appList">
                    <div class="app-name">[{{ app.appName }}]</div>
                    <svg class="app-icon icon-svg" v-if="app.isSubmit">
                        <use xlink:href="#icon-duihao"></use>
                    </svg>
                    <div class="app-item-progress">
                        <a-tooltip :title="item.name + ':' + item.value + item.unit"
                            v-for="item in app.appStatisticList">
                            <a-progress class="progress-item" :percent="item.percent" type="circle" status="normal"
                                :width="20" :strokeWidth="10" :strokeColor="'#AB3982'">
                                <template #format>
                                    <span>{{ item.value }}</span>
                                </template>
                            </a-progress>
                        </a-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        inductionList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
        }
    },
    async mounted() {
    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.int-item {
    margin-bottom: 10px;

    .int-item-info {
        display: flex;
        align-items: center;
        user-select: none;

        &.hasApp {
            cursor: pointer;

            .int-item-icon {
                opacity: 1;
            }
        }

        &.unFoldApp {
            .int-item-icon {
                transform: rotate(0);
            }
        }

        .int-item-icon {
            width: 30px;
            opacity: 0;
            transition: all 0.3s;
            transform: rotate(180deg);
            color: #fff;
        }

        .int-item-progress {
            display: flex;
            align-items: center;
            margin-left: auto;

            .progress-item {
                margin-left: 10px;
            }
        }
    }

    .int-item-apps {
        margin: 10px 0;
        margin-left: 30px;
        width: calc(100% - 30px);
        padding: 5px 10px;
        border-radius: 7px;
        border: 1px solid #6c6c829e;
        background-image: linear-gradient(to bottom, #ab398233, #ffb6c11a);

        .int-item-app {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .app-icon {
                margin-left: 10px;
            }

            .app-item-progress {
                display: flex;
                align-items: center;
                margin-left: auto;

                .progress-item {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>