<template>
  <div>
    <div class="q-q">
      <div class="q-index">[{{ item2.type }}] </div>
      <div class="q-html fillintheblank" v-html="item2.question"></div>
    </div>
    <div class="opt-list">
      <div class="stu-answer q-html" ref="renderRef" v-html="stuAnswer"></div>
    </div>
  </div>
</template>
<script>
import katex from 'katex'
export default {
  components: {
  },
  props: {
    stuAnswer: {
      type: Object,
      required: false
    },
    item2: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.renderLatexNode()
    })
  },
  methods: {
    renderLatexNode() {
      const mathNodes = this.$refs.renderRef.querySelectorAll('.math-view')
      for(const node of mathNodes) {
        try {
          const latex = node.dataset.latex
          if(latex) katex.render(latex, node, { displayMode: false, throwOnError: false })
        }
        catch {
          continue
        }
      }
    },
  },
}
</script>